import { Launch } from "@mui/icons-material";
import { IconButton, Link, Typography } from "@mui/material";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import * as React from "react";
import LinesEllipsis from "react-lines-ellipsis";

import { ComposableCard } from "components/ComposableCard";
import { ExternalLink } from "components/ExternalLink";
import { RichContent } from "components/RichContent";
import { Post } from "types/Post";
import { RssIcon } from "./RssIcon";
import { IntegrationLabels } from "../IntegrationLabels";
import { HidePostButton } from "screens/post-grid/containers/HidePostButton";

export interface RssFeedPostCardProps {
  post: Post;
  onHide: () => void;
}

export const RssFeedPostCard: React.FC<RssFeedPostCardProps> = ({
  post,
  onHide,
}) => (
  <ComposableCard
    type="selfText"
    renderLabels={() => <IntegrationLabels post={post} />}
    renderHeader={() => (
      <>
        <Typography
          sx={{ textAlign: "right", flexGrow: 0, flexShrink: 0 }}
          variant="caption"
          color="text.secondary"
          gutterBottom
        >
          {formatDistanceToNowStrict(new Date(post.content.createdAt))}
        </Typography>

        <Typography variant="h5" sx={{ flexGrow: 0, flexShrink: 0 }}>
          <Link
            underline="none"
            lineHeight="1.25em"
            component={ExternalLink}
            to={post.content.url}
          >
            <LinesEllipsis
              text={post.content.title}
              maxLine="3"
              ellipsis="…"
              trimRight
              basedOn="letters"
            />
          </Link>
        </Typography>
      </>
    )}
    renderContent={() => <RichContent>{post.content.text}</RichContent>}
    renderServiceIcon={() => <RssIcon />}
    renderActions={() => (
      <>
        <HidePostButton sx={{ ml: 1 }} post={post} onHide={onHide} />

        <IconButton
          sx={{ ml: 1 }}
          component={ExternalLink}
          href={post.content.url}
        >
          <Launch />
        </IconButton>
      </>
    )}
  />
);
