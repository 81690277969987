import { Link, Typography } from "@mui/material";
import * as he from "he";
import * as React from "react";
import ReactMarkdown from "react-markdown";

import { ExternalLink } from "./ExternalLink";

export interface MarkdownContentProps {
  children: string;
}

export const MarkdownContent: React.FC<MarkdownContentProps> = ({
  children,
  ...rest
}) => (
  <div style={{ wordBreak: "break-word" }}>
    <ReactMarkdown
      linkTarget="_blank"
      components={{
        h1: ({ children }) => <Typography children={children} variant="h5" />,
        h2: ({ children }) => <Typography children={children} variant="h6" />,
        h3: ({ children }) => (
          <Typography children={children} variant="subtitle1" />
        ),
        h4: ({ children }) => (
          <Typography children={children} variant="subtitle1" />
        ),
        h5: ({ children }) => (
          <Typography children={children} variant="subtitle1" />
        ),
        h6: ({ children }) => (
          <Typography children={children} variant="subtitle1" />
        ),
        a: ({ href, children }) => (
          <Link component={ExternalLink} href={href} children={children} />
        ),
      }}
    >
      {he.decode(children)}
    </ReactMarkdown>
  </div>
);
