import { Link, Typography, TypographyProps } from "@mui/material";
import Autolinker, { HashtagConfig } from "autolinker";
import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";

export interface RichContentProps extends TypographyProps {
  source?: HashtagConfig;
  children: string;
}

export const RichContent: React.FC<RichContentProps> = ({
  source,
  children,
  ...rest
}) => (
  <Typography
    {...rest}
    sx={{ wordBreak: "break-word", ...rest.sx }}
    dangerouslySetInnerHTML={{
      __html: Autolinker.link(children.replace(/\n/g, "<br />"), {
        newWindow: true,
        hashtag: source,
        // sanitizeHtml: true,
        replaceFn: (match) =>
          // Hack: for some reason,
          renderToStaticMarkup(
            <Link underline="none" href={match.getAnchorHref()} target="_blank">
              {match.getAnchorText()}
            </Link>
          ),
      }),
    }}
  />
);
