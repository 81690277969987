import { Button, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";

import { SIDE_BAR_WIDTH } from "containers/SideBarContainer";
import { useIsMobile } from "hooks/useIsMobile";

export const Error404: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : `calc(100vw - ${SIDE_BAR_WIDTH}px)`,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "75%", textAlign: "center" }}>
        <Toolbar />
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Dead link!
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }} paragraph>
          You somehow found an unknown page!
          <br />
        </Typography>

        <Button component={Link} to="/">
          Back to safety
        </Button>
      </Box>
    </Box>
  );
};
