import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";

import { Post } from "types/Post";

export interface HidePostConfirmProps {
  children: (renderProps: {
    confirm: (post: Post) => void;
  }) => React.ReactElement;
  onConfirm: (post: Post) => void;
}

const getFormattedAuthorFromPost = (post: Post, author: string): string => {
  switch (post.type) {
    case "redditSearch":
      return `/u/${author}`;

    case "twitterSearch":
      return `@${author}`;

    case "rssFeed":
    default:
      return author;
  }
};

const getFormattedTitleFromPost = (post: Post): string => {
  if (!post) return null;

  if (post.content.title) return `“${post.content.title}”`;
  if (post.content.author?.username)
    return `by ${getFormattedAuthorFromPost(
      post,
      post.content.author.username
    )}`;

  return `Id(${post._id})`;
};

export const HidePostConfirm: React.FC<HidePostConfirmProps> = ({
  onConfirm,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const [post, setPost] = React.useState<Post>();

  const formattedPostTitle = getFormattedTitleFromPost(post);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Hide post</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to hide post {formattedPostTitle}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="error" onClick={() => setOpen(false)}>
            Nope
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              return onConfirm(post);
            }}
          >
            Yup
          </Button>
        </DialogActions>
      </Dialog>

      {children({
        confirm: (post) => {
          setPost(post);
          setOpen(true);
        },
      })}
    </>
  );
};
