import { gql, useQuery } from "@apollo/client";
import {
  ExpandMore,
  Help,
  Reddit,
  RssFeed,
  Twitter,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";

const getIntegrations = gql`
  query GetIntegrations {
    integrations {
      _id
      name
      type
    }
  }
`;

export const IntegrationsScreen: React.FC = () => {
  const { loading, error, data } = useQuery(getIntegrations, {});

  return (
    <Box p={2}>
      {data?.integrations.map((integration) => (
        <Accordion key={integration._id}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              {(() => {
                switch (integration.type) {
                  case "redditSearch":
                    return <Reddit />;

                  case "twitterSearch":
                    return <Twitter />;

                  case "rssFeed":
                    return <RssFeed />;

                  default:
                    return <Help />;
                }
              })()}
            </Box>

            <Typography>{integration.name}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography>TEST</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
