import { createTheme, PaletteOptions, ThemeProvider } from "@mui/material";
import { ThemeContext } from "@mui/styled-engine";
import { useTheme } from "@mui/system";
import * as React from "react";

import { BodyBackgroundColorer } from "components/BodyBackgroundColorer";

type ThemeMode = "dark" | "light";
const THEME_MODE: ThemeMode = "dark";

const palettes: Record<ThemeMode, Partial<PaletteOptions>> = {
  light: {},
  dark: {
    primary: {
      // main: "#1162BC",
      main: "#A5CFFF",
    },
    background: {
      default: "#020A13",
      paper: "#061837",
    },
  },
};

export const Theme: React.FC = ({ children }) => {
  const theme = useTheme();

  const createdTheme = createTheme({
    palette: {
      mode: THEME_MODE,
      ...palettes[THEME_MODE],
    },
  });

  return (
    <ThemeProvider
      theme={createTheme({
        ...createdTheme,
        components: {
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: createdTheme.palette.background?.paper, // TODO: make it work
              },
            },
          },
        },
      })}
    >
      <ThemeContext.Consumer>
        {(value: any) => <BodyBackgroundColorer theme={value} />}
      </ThemeContext.Consumer>

      {children}
    </ThemeProvider>
  );
};
