import { VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SxProps } from "@mui/system";
import * as React from "react";

import { Post } from "types/Post";

export interface HidePostButtonProps {
  post: Post;
  onHide: () => void;
  sx?: SxProps;
}

export const HidePostButton: React.FC<HidePostButtonProps> = ({
  post,
  onHide,
  sx,
}) => (
  <IconButton sx={sx} onClick={onHide}>
    <VisibilityOff />
  </IconButton>
);
