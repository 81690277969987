import { CssBaseline, ScopedCssBaseline } from "@mui/material";
import * as React from "react";

import { Theme } from "./containers/Theme";
import { ApiProvider } from "./containers/ApiProvider";
import { Layout } from "./containers/Layout";
import { Router } from "./screens";
import { HashRouter } from "react-router-dom";

export const App: React.FC = () => {
  const BaseRouter = HashRouter;

  return (
    <ApiProvider>
      <BaseRouter>
        <Theme>
          <CssBaseline />

          <Layout>
            <Router />
          </Layout>
        </Theme>
      </BaseRouter>
    </ApiProvider>
  );
};
