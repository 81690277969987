import { Box, Card, CardActions, CardContent, CardMedia } from "@mui/material";
import * as React from "react";

export type ComposableCardType = "mediaFirst" | "contentFirst" | "selfText";

export interface ComposableCardProps {
  type: ComposableCardType;
  renderLabels?: () => React.ReactElement;
  renderMedia?: () => React.ReactElement;
  renderHeader: () => React.ReactElement;
  renderContent?: () => React.ReactElement;
  renderServiceIcon?: () => React.ReactElement;
  renderActions: () => React.ReactElement;
}

const CARD_HEIGHT = 440;

export const ComposableCard: React.FC<ComposableCardProps> = ({
  type,
  renderLabels,
  renderMedia,
  renderHeader,
  renderContent,
  renderServiceIcon,
  renderActions,
}) => {
  const mediaSize = () => {
    switch (type) {
      case "mediaFirst":
        return 280;

      case "contentFirst":
        return 180;

      case "selfText":
      default:
        return 0;
    }
  };
  const parentRef = React.useRef();

  return (
    <Box sx={{ position: "relative" }}>
      {renderLabels ? (
        <Box sx={{ position: "absolute", top: 4, left: -8, zIndex: 4 }}>
          {renderLabels()}
        </Box>
      ) : null}

      <Card
        sx={{ height: CARD_HEIGHT, display: "flex", flexDirection: "column" }}
      >
        {renderMedia ? (
          <CardMedia
            sx={{ height: mediaSize, flexGrow: 0, flexShrink: 0 }}
            ref={parentRef}
          >
            <Box sx={{ width: "100%", height: "100%" }}>{renderMedia()}</Box>
          </CardMedia>
        ) : null}

        <CardContent
          sx={
            renderContent
              ? {
                  flexGrow: 0,
                  flexShrink: 0,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  pb: 0,
                }
              : {
                  flexGrow: 1,
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  pb: 0,
                }
          }
        >
          {renderHeader()}
        </CardContent>

        {renderContent ? (
          <CardContent
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "overlay",
              pb: 0,
              "& p:first-of-type": {
                mt: 0,
              },
              "& p:last-of-type": {
                mb: 0,
              },
            }}
          >
            {renderContent()}
          </CardContent>
        ) : null}

        <CardActions
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {renderServiceIcon ? (
            <Box
              sx={{
                width: 40,
                height: 40,
                p: 0.5,
                mr: "auto",
                alignSelf: "flex-end",
              }}
            >
              {renderServiceIcon()}
            </Box>
          ) : null}

          {renderActions()}
        </CardActions>
      </Card>
    </Box>
  );
};
