import { Favorite, Launch, Repeat } from "@mui/icons-material";
import { IconButton, Link, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import * as React from "react";

import { ComposableCard } from "components/ComposableCard";
import { ExternalLink } from "components/ExternalLink";
import { RichContent } from "components/RichContent";
import { Post } from "types/Post";
import { TwitterIcon } from "./TwitterIcon";
import { IntegrationLabels } from "../IntegrationLabels";
import { HidePostButton } from "screens/post-grid/containers/HidePostButton";
import { Box } from "@mui/system";
import { TwitterPostActions } from "./TwitterPostActions";

export interface TwitterTextPostCardProps {
  post: Post;
  onHide: () => void;
}

export const TwitterTextPostCard: React.FC<TwitterTextPostCardProps> = ({
  post,
  onHide,
}) => (
  <ComposableCard
    type="selfText"
    renderLabels={() => <IntegrationLabels post={post} />}
    renderHeader={() => (
      <Typography
        variant="caption"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Link
          underline="none"
          component={ExternalLink}
          href={`https://twitter.com/${post.content.author.username}`}
        >
          @{post.content.author.username}
        </Link>

        <Typography color="text.secondary">
          {formatDistanceToNowStrict(new Date(post.content.createdAt))}
        </Typography>
      </Typography>
    )}
    renderContent={() => (
      <RichContent source="twitter">{post.content.text}</RichContent>
    )}
    renderServiceIcon={() => <TwitterIcon />}
    renderActions={() => <TwitterPostActions post={post} onHide={onHide} />}
  />
);
