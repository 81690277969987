import { Link, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import * as React from "react";
import LinesEllipsis from "react-lines-ellipsis";

import { ComposableCard } from "components/ComposableCard";
import { ExternalLink } from "components/ExternalLink";
import { PostScraps } from "containers/PostScraps";
import { PostScrapsMedia } from "containers/PostScraps/PostScrapsMedia";
import { Post } from "types/Post";
import { RedditIcon } from "./RedditIcon";
import { IntegrationLabels } from "../IntegrationLabels";
import { RedditPostActions } from "./RedditPostActions";

export interface RedditMediaPostCardProps {
  post: Post;
  onHide: () => void;
}

export const RedditMediaPostCard: React.FC<RedditMediaPostCardProps> = ({
  post,
  onHide,
}) => {
  return (
    <ComposableCard
      type="mediaFirst"
      renderLabels={() => (
        <IntegrationLabels
          flairs={[post.content.content.flair].filter((c) => c)}
          post={post}
        />
      )}
      renderMedia={() => (
        <PostScraps
          urls={[post.content.content.video, post.content.content.url].filter(
            (c) => c
          )}
        >
          {({ scraps, loading, error }) =>
            loading ? null : (
              <PostScrapsMedia
                sx={{ width: "100%", height: "100%" }}
                type={(() => {
                  if (post.content.nsfw) return "nsfw";
                  if (post.content.spoiler) return "spoiler";
                  return undefined;
                })()}
                scraps={scraps}
              />
            )
          }
        </PostScraps>
      )}
      renderHeader={() => (
        <>
          <Typography
            variant="caption"
            sx={{ display: "flex", justifyContent: "space-between" }}
            gutterBottom
          >
            <Link
              underline="none"
              component={ExternalLink}
              href={post.content.subreddit.url}
            >
              {post.content.subreddit.fullName}
            </Link>

            <Typography variant="inherit" color="text.secondary">
              {formatDistanceToNowStrict(new Date(post.content.createdAt))}
            </Typography>
          </Typography>
          <Typography variant="h6" gutterBottom>
            <Link
              underline="none"
              lineHeight="1.25em"
              component={ExternalLink}
              href={post.content.source}
            >
              <LinesEllipsis
                text={post.content.title}
                maxLine="2"
                ellipsis="…"
                trimRight
                basedOn="letters"
              />
            </Link>
          </Typography>
        </>
      )}
      renderServiceIcon={() => <RedditIcon />}
      renderActions={() => <RedditPostActions post={post} onHide={onHide} />}
    />
  );
};
