import { Grid, GridProps } from "@mui/material";
import * as React from "react";
import { ComposableCardSkeleton } from "components/ComposableCardSkeleton";

export interface SkeletonPostGridProps extends GridProps {
  postsPerPage: number;
}

export const SkeletonPostGrid: React.FC<SkeletonPostGridProps> = ({
  postsPerPage,
  ...rest
}) => (
  <>
    {new Array(postsPerPage).fill(undefined).map((_, index) => (
      <Grid {...rest} key={index}>
        <ComposableCardSkeleton />
      </Grid>
    ))}
  </>
);
