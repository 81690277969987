import { Card } from "@mui/material";
import * as React from "react";

import { Post } from "types/Post";
import { TwitterMediaPostCard } from "./TwitterMediaPostCard";
import { TwitterTextPostCard } from "./TwitterTextPostCard";

export interface TwitterSearchPostCardProps {
  post: Post;
  onHide: () => void;
}

export const TwitterSearchPostCard: React.FC<TwitterSearchPostCardProps> = ({
  post,
  onHide,
}) => {
  if (post.content.urls?.length > 0) {
    return <TwitterMediaPostCard post={post} onHide={onHide} />;
  }

  return <TwitterTextPostCard post={post} onHide={onHide} />;
};
