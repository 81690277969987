import { gql, useQuery } from "@apollo/client";
import * as React from "react";
import { useTimeoutFn } from "react-use";

import { Scrap } from "types/Scrap";

const getScrapsFromUrls = gql`
  query GetScrapsFromUrls($urls: [String!]!) {
    scraps(urls: $urls) {
      _id
      value {
        images
        video
        url
      }
    }
  }
`;

export interface PostScrapsProps {
  urls: string[];
  children: (renderProps: {
    scraps: Scrap[];
    loading?: boolean;
    error?: Error;
  }) => React.ReactElement;
}

export const PostScraps: React.FC<PostScrapsProps> = ({ urls, children }) => {
  const { loading, error, data, stopPolling } = useQuery(getScrapsFromUrls, {
    variables: { urls },
    pollInterval: 2 * 1000, // 2s
  });
  if (data?.scraps?.length === urls.length) {
    stopPolling();
  }
  useTimeoutFn(stopPolling, 30 * 1000);

  return children({ scraps: data?.scraps ?? [], loading, error });
};
