import { Apps, Refresh, VerticalAlignTop } from "@mui/icons-material";
import { Avatar, Button, Grow, IconButton, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useWindowScroll } from "react-use";

import { NavBar } from "containers/NavBar";
import { Link } from "react-router-dom";
import { client } from "lib/api";

export interface PostGridNavBarProps {
  homeUrl: string;
  icon?: string;
  bannerUrls?: string[];
}

export const PostGridNavBar: React.FC<PostGridNavBarProps> = ({
  homeUrl,
  icon,
  bannerUrls,
}) => {
  const theme = useTheme();
  const windowScroll = useWindowScroll();

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <NavBar
      sx={
        bannerUrls?.length > 0
          ? {
              backgroundImage: `url(${JSON.stringify(bannerUrls[0])})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }
          : undefined
      }
      actions={
        <Box
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box component={Link} to={homeUrl}>
            <Avatar
              sx={{
                width: 32,
                height: 32,
                p: 0.5,
                bgcolor: theme.palette.primary.main,
              }}
              src={icon}
              variant="rounded"
            >
              <Apps />
            </Avatar>
          </Box>

          <Box>
            <Grow in={windowScroll.y !== 0}>
              <Button
                sx={{ minWidth: 0, mr: 2, p: 0.5, borderRadius: "100em" }}
                variant="contained"
                color="primary"
                onClick={scrollToTop}
              >
                <VerticalAlignTop />
              </Button>
            </Grow>

            <Button
              sx={{ minWidth: 0, p: 0.5, borderRadius: "100em" }}
              variant="contained"
              color="primary"
              onClick={() => {
                client.resetStore();
                scrollToTop();
              }}
            >
              <Refresh />
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
