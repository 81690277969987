import * as React from "react";
import OpenApp from "react-open-app";

export const ExternalLink: React.FC<
  React.PropsWithRef<any> & React.LinkHTMLAttributes<any>
> = React.forwardRef(({ href, onClick, ...rest }, ref) => (
  <span ref={ref as React.Ref<any>}>
    <OpenApp {...rest} href={href} blank />
  </span>
));
