import * as React from "react";
import { gql, useQuery } from "@apollo/client";

const getTopics = gql`
  query GetTopics {
    topics {
      _id
      name
      iconUrl
    }
  }
`;

export interface Topic {
  _id: string;
  name: string;
  iconUrl: string;
}

export interface TopicListProps {
  children: (renderProps: {
    topics: Topic[];
    loading?: boolean;
    error?: Error;
  }) => React.ReactElement;
}

export const TopicList: React.FC<TopicListProps> = ({ children }) => {
  const { loading, error, data } = useQuery(getTopics, {});

  return children({ topics: data?.topics ?? [], loading, error });
};
