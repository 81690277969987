import { gql, useQuery } from "@apollo/client";
import * as React from "react";

const queries = {
  topics: gql`
    query getElem($id: ObjectID!) {
      entry: topic(_id: $id) {
        _id
        name
        icon: iconUrl
        bannerUrls
      }
    }
  `,
  collections: gql`
    query getElem($id: ObjectID!) {
      entry: collection(_id: $id) {
        _id
        name
        icon
      }
    }
  `,
};

export interface PostGridMetaProps {
  type: "topics" | "collections" | null;
  id: string | null;
  children: (renderProps: {
    loading: boolean;
    error: Error;
    name: string;
    icon: string;
    bannerUrls?: string[];
  }) => React.ReactElement;
}

export const PostGridMeta: React.FC<PostGridMetaProps> = ({
  type,
  id,
  children,
}) => {
  const { loading, error, data } = !type
    ? {
        loading: false,
        error: null,
        data: {
          entry: {
            _id: null,
            name: "All",
            icon: null,
            bannerUrls: null,
          },
        },
      }
    : useQuery(
        (() => {
          switch (type) {
            case "topics":
              return queries.topics;

            case "collections":
              return queries.collections;

            default:
              return null;
          }
        })(),
        { variables: { id } }
      );

  return children({
    ...data?.entry,
    loading,
    error,
  });
};
