import { Skeleton } from "@mui/material";
import * as React from "react";

import { ComposableCard, ComposableCardType } from "./ComposableCard";

const POSSIBLE_CARD_TYPES: ComposableCardType[] = [
  "mediaFirst",
  "contentFirst",
  "selfText",
];

export const ComposableCardSkeleton: React.FC = () => {
  const type =
    POSSIBLE_CARD_TYPES[Math.floor(Math.random() * POSSIBLE_CARD_TYPES.length)];

  return (
    <ComposableCard
      type={type}
      renderHeader={() => (
        <>
          <Skeleton />
          <Skeleton sx={{ height: 64 }} />
        </>
      )}
      renderMedia={
        type !== "selfText"
          ? () => (
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: "100%" }}
              />
            )
          : undefined
      }
      renderContent={
        type !== "mediaFirst"
          ? () => (
              <Skeleton
                variant="rectangular"
                sx={{ height: "100%", borderRadius: 1 }}
              />
            )
          : undefined
      }
      renderActions={() => (
        <Skeleton variant="circular" width={40} height={40} />
      )}
    />
  );
};
