import { Box } from "@mui/system";
import { ComposableCardSkeleton } from "components/ComposableCardSkeleton";
import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";

import { Post } from "types/Post";
import { IntegrationLabels } from "./IntegrationLabels";
import { RedditSearchPostCard } from "./RedditSearchPostCard";
import { RssFeedPostCard } from "./RssFeedPostCard";
import { TwitterSearchPostCard } from "./TwitterSearchPostCard";

export interface PostCardProps {
  post: Post;
  onHide: () => void;
}

export const POST_CARD = 440;

export const PostCard: React.FC<PostCardProps> = ({ post, onHide }) => (
  <VisibilitySensor
    partialVisibility={true}
    offset={{ top: -960, bottom: -960 }}
  >
    {({ isVisible, ...rest }) => (
      <Box sx={{ height: POST_CARD }}>
        {isVisible ? (
          (() => {
            switch (post.type) {
              case "redditSearch":
                return <RedditSearchPostCard post={post} onHide={onHide} />;

              case "twitterSearch":
                return <TwitterSearchPostCard post={post} onHide={onHide} />;

              case "rssFeed":
                return <RssFeedPostCard post={post} onHide={onHide} />;

              default:
                return null;
            }
          })()
        ) : (
          <ComposableCardSkeleton />
        )}
      </Box>
    )}
  </VisibilitySensor>
);
