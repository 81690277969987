import * as React from "react";
import ReactPlayer from "react-player";

import { ExternalLink } from "components/ExternalLink";

export interface PostScrapsVideoProps {
  url: string;
  orientation: "portrait" | "landscape";
}

export const PostScrapsVideo: React.FC<PostScrapsVideoProps> = ({
  url,
  orientation,
}) => (
  <ExternalLink
    href={url}
    style={{ width: "100%", height: "100%", display: "block" }}
  >
    <ReactPlayer
      width="100%"
      height="100%"
      url={url.replace(/f=sd/, "f=xl")}
      playing
      loop
      volume={0}
      muted
      controls={false}
      playsinline
      config={{
        youtube: {
          playerVars: {
            vq: "hd720",
            disablekb: 1,
            rel: 0,
            enablejsapi: 1,
            wmode: "opaque",
            border: 0,
          },
        },
        file: {
          hlsOptions: {
            capLevelToPlayerSize: true,
            startLevel: 5,
          },
        },
      }}
    />
  </ExternalLink>
);
