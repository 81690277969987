import * as React from "react";
import { Routes, Route } from "react-router-dom";

import { Error404 } from "./errors/Error404";
import { IntegrationsScreen } from "./integrations";
import { PlaygroundScreen } from "./playground";
import { PostGridScreen } from "./post-grid";

export const Router: React.FC = () => (
  <Routes>
    <Route path="/" element={<PostGridScreen />} />
    <Route path="/collections/:collectionId" element={<PostGridScreen />} />
    <Route path="/topics/:topicId" element={<PostGridScreen />} />

    <Route path="/integrations" element={<IntegrationsScreen />} />

    <Route path="/playground" element={<PlaygroundScreen />} />

    <Route path="*" element={<Error404 />} />
  </Routes>
);
