import { gql, useMutation } from "@apollo/client";
import { Grid, Paper } from "@mui/material";
import * as React from "react";

import { Post } from "types/Post";
import { PostCard } from "../components/PostCard";
import { SkeletonPostGrid } from "../components/SkeletonPostGrid";
import { HidePostConfirm } from "./HidePostConfirm";

const hidePost = gql`
  mutation HidePost($id: ObjectID!) {
    hidePost(id: $id)
  }
`;

export interface PostGridProps {
  postsPerPage: number;
  loading?: boolean;
  error?: Error;
  posts: Post[];
  fetchMore: () => void;
}

export const PostGrid: React.FC<PostGridProps> = ({
  postsPerPage,
  loading,
  error,
  posts,
  fetchMore,
}) => {
  const [mutateHidePost] = useMutation(hidePost, {
    refetchQueries: ["GetPosts"],
  });

  return loading ? (
    <SkeletonPostGrid
      postsPerPage={postsPerPage}
      item
      xs={12}
      sm={6}
      lg={4}
      xl={3}
    />
  ) : (
    <HidePostConfirm
      onConfirm={(post) => {
        mutateHidePost({ variables: { id: post._id } });
      }}
    >
      {({ confirm }) => (
        <>
          {posts.map((post) => (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={post._id}>
              <PostCard post={post} onHide={() => confirm(post)} />
            </Grid>
          ))}
        </>
      )}
    </HidePostConfirm>
  );
};
