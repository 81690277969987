import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";

import { PostScrapsMedia } from "containers/PostScraps/PostScrapsMedia";
import { Scrap } from "types/Scrap";

export const PlaygroundScreen: React.FC = () => {
  const galleryScraps: Scrap[] = [
    {
      _id: "1",
      value: {
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/250px-Image_created_with_a_mobile_phone.png",
        video: null,
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/250px-Image_created_with_a_mobile_phone.png",
      },
    },

    {
      _id: "2",
      value: {
        image:
          "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
        video: null,
        url: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
      },
    },

    {
      _id: "3",
      value: {
        image:
          "https://img-19.ccm2.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
        video: null,
        url: "https://img-19.ccm2.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
      },
    },

    {
      _id: "4",
      value: {
        image:
          "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8Y2hhbmdlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80",
        video: null,
        url: "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8Y2hhbmdlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80",
      },
    },

    {
      _id: "5",
      value: {
        image: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
        video: null,
        url: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
      },
    },
  ];

  return (
    <Box sx={{ width: 320, height: 240, p: 2 }}>
      <Paper sx={{ width: "100%", height: "100%" }}>
        <PostScrapsMedia
          sx={{ width: "100%", height: "100%" }}
          scraps={galleryScraps.slice(0, 1)}
        />
      </Paper>
    </Box>
  );
};
