import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";

export const client = new ApolloClient({
  link: from([
    new RetryLink({
      delay: {
        initial: 100,
        max: 60 * 1000, // 1min
        jitter: true,
      },
      attempts: {
        max: 8, // i.e., max `100 * 2^8`ms === 25.6s
      },
    }),
    new HttpLink({
      uri: `${window.location.protocol}//${window.location.hostname}:7338/graphql`,
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          posts: {
            keyArgs: ["topic", "collection"],
            merge: (existing, incoming) => ({
              ...incoming,
              entries: [
                ...(existing?.entries ?? []),
                ...(incoming?.entries ?? []),
              ],
            }),
          },
        },
      },
    },
  }),
});
