import { SwipeableHandlers, useSwipeable } from "react-swipeable";
import { useWindowSize } from "react-use";

export function useSideBarOpen(options: {
  onOpen?: () => void;
  onClose?: () => void;
}): SwipeableHandlers {
  const { width: windowWidth } = useWindowSize();
  return useSwipeable({
    onSwipedLeft: options.onClose,
    onSwipedRight: options.onOpen,
    delta: windowWidth * (25 / 100), // 25% of the window width
    preventDefaultTouchmoveEvent: true,
  });
}
