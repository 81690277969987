import { CircularProgress, Grid, Skeleton, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useMatch } from "react-router-dom";

import { useIsMobile } from "hooks/useIsMobile";
import { PostGridQuery } from "./containers/PostGridQuery";
import { PostGrid } from "./containers/PostGrid";
import { TextDivider } from "components/TextDivider";
import { PostGridNavBar } from "./containers/PostGridNavBar";
import { PostGridMeta } from "./containers/PostGridMeta";
import { LayoutContext } from "context/LayoutContext";

export const POST_PER_PAGE_COUNT = 16;

export const PostGridScreen: React.FC = () => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const {
    params: { id: sectionId, type: section = "all" },
  } = useMatch("/:type/:id") ?? { params: { id: null, type: null } };

  React.useEffect(() => window.scrollTo({ top: 0, behavior: "auto" })); // Always scroll back to on rerender

  return (
    <LayoutContext.Consumer>
      {({ sideBar: { open: sideBarOpenState } }) => (
        <PostGridMeta type={section as any} id={sectionId}>
          {({ loading, error, name, icon, bannerUrls = [] }) => (
            <Box>
              <PostGridNavBar
                homeUrl={pathname}
                icon={icon}
                bannerUrls={bannerUrls}
              />

              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                }}
              >
                <PostGridQuery
                  topic={section === "topics" ? sectionId : null}
                  collection={section === "collections" ? sectionId : null}
                  postsPerPage={POST_PER_PAGE_COUNT}
                >
                  {({ posts, loading, error, hasMore, fetchMore, refetch }) => (
                    <InfiniteScroll
                      key={`inf-scr-${isMobile}`}
                      dataLength={posts.length}
                      next={fetchMore}
                      refreshFunction={refetch}
                      pullDownToRefresh={!sideBarOpenState && isMobile}
                      pullDownToRefreshThreshold={40}
                      pullDownToRefreshContent={
                        <TextDivider sx={{ mt: 4 }}>
                          Pull down to refresh
                        </TextDivider>
                      }
                      releaseToRefreshContent={
                        <TextDivider sx={{ mt: 4 }}>
                          Release to refresh
                        </TextDivider>
                      }
                      hasMore={hasMore}
                      loader={
                        <TextDivider sx={{ pb: 2 }}>
                          <CircularProgress sx={{ marginBottom: "-0.5em" }} />
                        </TextDivider>
                      }
                      endMessage={
                        <TextDivider sx={{ pb: 2 }}>
                          End of grid reached
                        </TextDivider>
                      }
                    >
                      <Grid container p={3} spacing={2}>
                        <PostGrid
                          postsPerPage={POST_PER_PAGE_COUNT}
                          posts={posts}
                          loading={loading}
                          error={error}
                          fetchMore={fetchMore}
                        />
                      </Grid>
                    </InfiniteScroll>
                  )}
                </PostGridQuery>

                {isMobile ? <Toolbar /> : null}
              </Box>
            </Box>
          )}
        </PostGridMeta>
      )}
    </LayoutContext.Consumer>
  );
};
