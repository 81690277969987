import { ErrorOutline, WarningAmberOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import * as React from "react";

export type SpoilerBoxType = "spoiler" | "nsfw" | undefined;

export interface SpoilerBoxProps extends BoxProps {
  type?: SpoilerBoxType;
  text?: boolean;
}

export const SpoilerBox: React.FC<SpoilerBoxProps> = ({
  type,
  text,
  children,
  ...props
}) => {
  const [isContentVisible, setIsContentVisible] = React.useState(false);
  const startOpen = !type;

  if (startOpen) {
    return <>{children}</>;
  }

  return (
    <Box
      {...props}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
          opacity: !isContentVisible ? 1 : 0,
          cursor: !isContentVisible ? "pointer" : undefined,
          pointerEvents: !isContentVisible ? undefined : "none",
          transition: "opacity 200ms ease-in-out",
        }}
        onClickCapture={(event) => {
          event.stopPropagation();
          setIsContentVisible(true);
        }}
      >
        {type === "nsfw" ? (
          <Button
            variant="outlined"
            sx={{ bgcolor: "rgba(0, 0, 0, 0.25)" }}
            startIcon={<WarningAmberOutlined />}
            color="error"
          >
            NSFW
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              bgcolor: "rgba(0, 0, 0, 0.25)",
            }}
            startIcon={<ErrorOutline />}
          >
            Spoiler
          </Button>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          filter: (() => {
            if (text) {
              return !isContentVisible ? "blur(0.2rem)" : "blur(0rem)";
            }
            return !isContentVisible ? "blur(1rem)" : "blur(0rem)";
          })(),
          transition: "filter 200ms ease-in-out",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: !isContentVisible ? "hidden" : undefined,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
