import { Box, SxProps } from "@mui/system";
import { ExternalLink } from "components/ExternalLink";
import * as React from "react";
import { PostScrapsImageGalleryItem } from "./PostScrapsImageGalleryItem";

export interface PostScrapsImageGalleryProps {
  medias: { image: string; url: string }[];
  sx?: SxProps;
}

const GALLERY_GAP_SIZE = 2; // px

export const PostScrapsImageGallery: React.FC<PostScrapsImageGalleryProps> = ({
  medias,
  sx,
}) => {
  const lines = (() => {
    if (medias.length >= 4) {
      const more = medias.slice(4, medias.length);
      return [
        {
          urls: medias.slice(0, 2),
        },
        {
          urls: medias.slice(2, 4),
          more: more.length > 0 ? more : undefined,
        },
      ];
    } else if (medias.length === 3) {
      return [
        {
          urls: medias.slice(0, 1),
        },
        {
          urls: medias.slice(1, 3),
        },
      ];
    } else if (medias.length === 2) {
      return [
        {
          urls: medias.slice(0, 2),
        },
      ];
    }

    return [{ urls: medias.slice(0, 1) }];
  })();

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        gap: `${GALLERY_GAP_SIZE}px`,
      }}
    >
      {lines.map((line, lineIdx) => (
        <Box
          key={lineIdx}
          sx={{
            display: "flex",
            width: "100%",
            height: `calc(100% / ${lines.length})`,
            flexWrap: "nowrap",
            flexBasis: 0,
            flexGrow: 1,
            gap: `${GALLERY_GAP_SIZE}px`,
          }}
        >
          {line.urls.map((url, urlIdx) => (
            <PostScrapsImageGalleryItem
              key={urlIdx}
              sx={{
                flexBasis: 0,
                flexGrow: 1,
              }}
              media={url.image}
              url={url.url}
            />
          ))}

          {line.more && line.more.length > 0 ? (
            <PostScrapsImageGalleryItem
              sx={{
                flexBasis: 0,
                flexGrow: 1,
              }}
              media={line.more[0].image}
              url={line.more[0].url}
              more={line.more.length}
            />
          ) : null}
        </Box>
      ))}
    </Box>
  );
};
