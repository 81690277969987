import _flatten from "lodash/flatten";
import { Paper, Skeleton } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import * as React from "react";
import ReactPlayer from "react-player";

import { Scrap } from "types/Scrap";
import { PostScrapsImageGallery } from "./PostScrapsImageGallery";
import { PostScrapsVideo } from "./PostScrapsVideo";
import { SpoilerBox, SpoilerBoxType } from "components/SpoilerBox";

export interface PostScrapsMediaProps {
  type?: SpoilerBoxType;
  scraps: Scrap[];
  sx?: SxProps;
}

export const PostScrapsMedia: React.FC<PostScrapsMediaProps> = ({
  type,
  scraps,
  sx,
}) => {
  const videoScrap = scraps.find(
    (scrap) =>
      scrap.value.video &&
      (ReactPlayer.canPlay(scrap.value.video) ||
        /\/\/v\.redd\.it/.test(scrap.value.video))
  );
  const imageMedias = _flatten(
    scraps.map((imageScrap) =>
      (imageScrap.value.images ?? []).map((image) => ({
        image,
        url: imageScrap.value.url,
      }))
    )
  ).filter((s) => s);

  const notFound = !videoScrap && imageMedias.length <= 0;

  return (
    <Paper
      elevation={2}
      sx={{
        ...sx,
        position: "relative",
        overflow: "hidden",
        "& video, iframe, embed, option, audio, canvas, object, applet": {
          objectFit: "cover",
          objectPosition: "center",
        },
      }}
    >
      {notFound ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <SpoilerBox type={type}>
          {videoScrap ? (
            <PostScrapsVideo
              orientation="landscape"
              url={videoScrap.value.video}
            />
          ) : (
            <PostScrapsImageGallery
              sx={{ width: "100%", height: "100%" }}
              medias={imageMedias}
            />
          )}
        </SpoilerBox>
      )}
    </Paper>
  );
};
