import { Paper, Stack, Typography } from "@mui/material";
import { LocalOffer, Tag } from "@mui/icons-material";
import { Box } from "@mui/system";
import * as React from "react";

import { Post } from "types/Post";
import { IntegrationCategory } from "types/Integration";
import { getIntegrationCategoryMeta } from "util/integrationCategoryMeta";
import { HoverableLabel } from "components/HoverableLabel";

export interface IntegrationLabelsProps {
  flairs?: string[];
  post: Post;
}

export const IntegrationLabels: React.FC<IntegrationLabelsProps> = ({
  flairs = [],
  post,
}) => {
  const labels: {
    key: string;
    name: string;
    category?: IntegrationCategory;
    open: boolean;
  }[] = flairs
    .map((flair) => ({
      key: flair,
      name: flair,
      open: true,
    }))
    .concat(
      post.integrations.map((integration) => ({
        key: integration._id,
        name: integration.name,
        category: integration.category,
        open: false,
      }))
    );

  return (
    <Stack spacing={1}>
      {labels.map((label) => {
        const integrationCategoryMeta = getIntegrationCategoryMeta(
          label.category as any
        );

        return (
          <HoverableLabel key={label.key} startOpen={label.open} edgeSize={8}>
            <Paper
              elevation={3}
              sx={{
                bgcolor: integrationCategoryMeta?.color,
                alignSelf: "flex-start",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                pl: 1,
                pr: 1,
                pt: 0.25,
                pb: 0.25,
                userSelect: "none",
              }}
            >
              <Box>
                {integrationCategoryMeta ? (
                  React.createElement(integrationCategoryMeta.icon, {
                    sx: {
                      width: 16,
                      height: 16,
                      display: "block",
                      mr: 1,
                    },
                  } as any)
                ) : (
                  <LocalOffer
                    sx={{
                      width: 16,
                      height: 16,
                      display: "block",
                      mr: 1,
                    }}
                  />
                )}
              </Box>

              <Box sx={{ display: "flex" }}>
                <Typography variant="caption">{label.name}</Typography>
              </Box>
            </Paper>
          </HoverableLabel>
        );
      })}
    </Stack>
  );
};
