import * as React from "react";
import { gql, useQuery } from "@apollo/client";

const getCollections = gql`
  query GetCollections {
    collections {
      _id
      name
      icon
    }
  }
`;

export interface Collection {
  _id: string;
  name: string;
  icon: string;
}

export interface CollectionListProps {
  children: (renderProps: {
    collections: Collection[];
    loading?: boolean;
    error?: Error;
  }) => React.ReactElement;
}

export const CollectionList: React.FC<CollectionListProps> = ({ children }) => {
  const { loading, error, data } = useQuery(getCollections, {});

  return children({ collections: data?.collections ?? [], loading, error });
};
