import * as React from "react";

import { Post } from "types/Post";
import { RedditMediaPostCard } from "./RedditMediaPostCard";
import { RedditTextPostCard } from "./RedditTextPostCard";

export interface RedditSearchPostCardProps {
  post: Post;
  onHide: () => void;
}

export const RedditSearchPostCard: React.FC<RedditSearchPostCardProps> = ({
  post,
  onHide,
}) =>
  post.content.content.type === "self" ? (
    <RedditTextPostCard post={post} onHide={onHide} />
  ) : (
    <RedditMediaPostCard post={post} onHide={onHide} />
  );
