import {
  DirectionsCar,
  Language,
  LocalFireDepartment,
  Policy,
  PushPin,
  QuestionAnswer,
  Stars,
  TrendingUp,
} from "@mui/icons-material";
import * as React from "react";

import { Integration, IntegrationCategory } from "types/Integration";

export interface IntegrationCategoryMeta {
  color: string;
  icon: React.FC;
}

export const integrationCategoryMetaDictionary: Record<
  IntegrationCategory,
  IntegrationCategoryMeta
> = {
  official: {
    color: "royalblue",
    icon: Stars,
  },

  popular: {
    color: "darkorange",
    icon: LocalFireDepartment,
  },

  discussion: {
    color: "teal",
    icon: QuestionAnswer,
  },

  ephemeral: {
    color: "orchid",
    icon: DirectionsCar,
  },

  sticky: {
    color: "seagreen",
    icon: PushPin,
  },

  rising: {
    color: "crimson",
    icon: TrendingUp,
  },

  website: {
    color: "lightslategrey",
    icon: Language,
  },

  leak: {
    color: "indigo",
    icon: Policy,
  },
};

export function getIntegrationCategoryMeta(
  category: IntegrationCategory
): IntegrationCategoryMeta {
  return integrationCategoryMetaDictionary[category];
}
