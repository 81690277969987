import { ClickAwayListener, Drawer } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";

import { useIsMobile } from "hooks/useIsMobile";
import { useSideBarOpen } from "hooks/useSideBarOpen";

export const SIDE_BAR_WIDTH = 280;

export interface SideBarContainerProps {
  isOpen: boolean;
  onChange: (isOpen: boolean) => void;
}

export const SideBarContainer: React.FC<SideBarContainerProps> = ({
  isOpen,
  onChange,
  children,
}) => {
  const width = SIDE_BAR_WIDTH;
  const isMobile = useIsMobile();
  const handlers = useSideBarOpen({
    onClose: () => onChange(false),
  });

  if (isMobile) {
    return (
      <Drawer
        {...handlers}
        open={isOpen}
        ModalProps={{ keepMounted: true }}
        variant="temporary"
        anchor="left"
        sx={{
          width,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width,
            overflow: "overlay",
            boxSizing: "border-box",
          },
        }}
      >
        <ClickAwayListener onClickAway={() => onChange(false)}>
          <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
        </ClickAwayListener>
      </Drawer>
    );
  }

  return (
    <Box sx={{ flexShrink: 0 }}>
      <Drawer
        ModalProps={{ keepMounted: true }}
        variant="permanent"
        anchor="left"
        sx={{
          width,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width,
            overflow: "overlay",
            boxSizing: "border-box",
          },
        }}
      >
        {children}
      </Drawer>
    </Box>
  );
};
