import { Link, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import * as React from "react";
import LinesEllipsis from "react-lines-ellipsis";

import { ComposableCard } from "components/ComposableCard";
import { ExternalLink } from "components/ExternalLink";
import { Post } from "types/Post";
import { RedditIcon } from "./RedditIcon";
import { IntegrationLabels } from "../IntegrationLabels";
import { RedditPostActions } from "./RedditPostActions";
import { MarkdownContent } from "components/MarkdownContent";
import { SpoilerBox } from "components/SpoilerBox";

export interface RedditTextPostCardProps {
  post: Post;
  onHide: () => void;
}

export const RedditTextPostCard: React.FC<RedditTextPostCardProps> = ({
  post,
  onHide,
}) => (
  <ComposableCard
    type="selfText"
    renderLabels={() => (
      <IntegrationLabels
        flairs={[post.content.content.flair].filter((c) => c)}
        post={post}
      />
    )}
    renderHeader={() => (
      <>
        <Typography
          variant="caption"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
          gutterBottom
        >
          <Link
            underline="none"
            component={ExternalLink}
            href={post.content.subreddit.url}
          >
            {post.content.subreddit.fullName}
          </Link>

          <Typography variant="inherit" color="text.secondary">
            {formatDistanceToNowStrict(new Date(post.content.createdAt))}
          </Typography>
        </Typography>

        <Typography variant="h5" gutterBottom>
          <Link
            underline="none"
            lineHeight="1.25em"
            component={ExternalLink}
            href={post.content.source}
          >
            <LinesEllipsis
              text={post.content.title}
              maxLine="3"
              ellipsis="…"
              trimRight
              basedOn="letters"
            />
          </Link>
        </Typography>
      </>
    )}
    renderContent={() => (
      <SpoilerBox
        type={(() => {
          if (post.content.nsfw) return "nsfw";
          if (post.content.spoiler) return "spoiler";
          return undefined;
        })()}
        text
      >
        <MarkdownContent>{post.content.content.text}</MarkdownContent>
      </SpoilerBox>
    )}
    renderServiceIcon={() => <RedditIcon />}
    renderActions={() => <RedditPostActions post={post} onHide={onHide} />}
  />
);
