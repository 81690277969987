import { Box, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import * as React from "react";

import { ExternalLink } from "components/ExternalLink";

export interface PostScrapsImageGalleryItemProps {
  media: string;
  url: string;
  more?: number;
  sx?: SxProps;
}

export const PostScrapsImageGalleryItem: React.FC<PostScrapsImageGalleryItemProps> =
  ({ media, url, more, sx }) => (
    <Box
      sx={{
        ...sx,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: `url(${JSON.stringify(media)}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        {!more ? (
          <ExternalLink
            style={{ display: "block", width: "100%", height: "100%" }}
            href={url}
          />
        ) : more ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0, 0, 0, 0.75)",
              userSelect: "none",
            }}
          >
            <Typography variant="h5" display="block">
              +{more}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
