import { Box } from "@mui/system";
import * as React from "react";

import { LayoutContext } from "context/LayoutContext";
import { SideBar } from "./SideBar";
import { useSideBarOpen } from "hooks/useSideBarOpen";

export const Layout: React.FC = ({ children }) => {
  const handlers = useSideBarOpen({
    onOpen: () => setSideBarOpen(true),
  });
  const [isSideBarOpen, setSideBarOpen] = React.useState<boolean>(false);

  return (
    <LayoutContext.Provider value={{ sideBar: { open: isSideBarOpen } }}>
      <Box {...handlers} sx={{ display: "flex", minHeight: "100vh" }}>
        <SideBar isOpen={isSideBarOpen} onChange={setSideBarOpen} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    </LayoutContext.Provider>
  );
};
