import { gql, useQuery } from "@apollo/client";
import * as React from "react";

import { client } from "lib/api";
import { Post } from "types/Post";

// TODO: remove count
const getPosts = gql`
  query GetPosts(
    $topic: ObjectID
    $collection: ObjectID
    $count: Int!
    $cursor: String
  ) {
    posts(
      topic: $topic
      collection: $collection
      count: $count
      cursor: $cursor
    ) {
      entries {
        _id
        type
        content
        integrations {
          _id
          name
          type
          category
        }
      }
      hasMore
      next
    }
  }
`;

export interface PostGridQueryProps {
  topic?: string;
  collection?: string;
  postsPerPage: number;
  children: (renderProps: {
    posts: Post[];
    loading?: boolean;
    error?: Error;
    hasMore: boolean;
    fetchMore: () => void;
    refetch: () => void;
  }) => React.ReactElement;
}

export const PostGridQuery: React.FC<PostGridQueryProps> = ({
  topic,
  collection,
  postsPerPage,
  children,
}) => {
  const { data, loading, error, fetchMore, refetch } = useQuery(getPosts, {
    variables: { topic, collection, count: postsPerPage, cursor: undefined },
  });
  React.useEffect(() => {
    client.resetStore();
  }, [topic, collection, postsPerPage]);

  return children({
    posts: data?.posts.entries ?? [],
    loading,
    error,
    hasMore: data?.posts.hasMore,
    fetchMore: () =>
      fetchMore({
        variables: {
          topic,
          collection,
          count: postsPerPage,
          cursor: data?.posts.next,
        },
      }),
    refetch: () => {
      client.resetStore().then(() => refetch());
    },
  });
};
