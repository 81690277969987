import { Favorite, Launch, Repeat, Stars } from "@mui/icons-material";
import { IconButton, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { formatDistanceToNowStrict } from "date-fns";
import * as React from "react";

import { ComposableCard } from "components/ComposableCard";
import { ExternalLink } from "components/ExternalLink";
import { RichContent } from "components/RichContent";
import { PostScraps } from "containers/PostScraps";
import { PostScrapsMedia } from "containers/PostScraps/PostScrapsMedia";
import { Post } from "types/Post";
import { TwitterIcon } from "./TwitterIcon";
import { IntegrationLabels } from "../IntegrationLabels";
import { HidePostButton } from "screens/post-grid/containers/HidePostButton";
import { TwitterPostActions } from "./TwitterPostActions";

export interface TwitterMediaPostCardProps {
  post: Post;
  onHide: () => void;
}

export const TwitterMediaPostCard: React.FC<TwitterMediaPostCardProps> = ({
  post,
  onHide,
}) => {
  return (
    <ComposableCard
      type="contentFirst"
      renderLabels={() => <IntegrationLabels post={post} />}
      renderMedia={() => (
        <PostScraps urls={post.content.urls}>
          {({ scraps, loading, error }) =>
            loading ? null : (
              <PostScrapsMedia
                sx={{ width: "100%", height: "100%" }}
                type={post.content.nsfw ? "nsfw" : undefined}
                scraps={scraps}
              />
            )
          }
        </PostScraps>
      )}
      renderHeader={() => (
        <Typography
          variant="caption"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Link
            underline="none"
            component={ExternalLink}
            href={`https://twitter.com/${post.content.author.username}`}
          >
            @{post.content.author.username}
          </Link>

          <Typography variant="inherit" color="text.secondary">
            {formatDistanceToNowStrict(new Date(post.content.createdAt))}
          </Typography>
        </Typography>
      )}
      renderContent={() => (
        <RichContent source="twitter">{post.content.text}</RichContent>
      )}
      renderServiceIcon={() => <TwitterIcon />}
      renderActions={() => <TwitterPostActions post={post} onHide={onHide} />}
    />
  );
};
