import { AppBar, Toolbar } from "@mui/material";
import { SxProps } from "@mui/system";
import * as React from "react";

import { useIsMobile } from "hooks/useIsMobile";

export interface NavBarProps {
  actions?: React.ReactElement;
  sx?: SxProps;
}

export const NavBar: React.FC<NavBarProps> = ({ actions, sx }) => {
  const isMobile = useIsMobile();

  return (
    <AppBar
      {...(isMobile
        ? {
            position: "fixed",
            sx: { ...sx, top: "auto", bottom: 0 },
          }
        : { position: "sticky", sx })}
    >
      <Toolbar variant={isMobile ? undefined : "dense"}>{actions}</Toolbar>
    </AppBar>
  );
};
