import { ArrowCircleUp, Comment, Launch } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";

import { ExternalLink } from "components/ExternalLink";
import { HidePostButton } from "screens/post-grid/containers/HidePostButton";
import { Post } from "types/Post";

export interface RedditPostActionsProps {
  post: Post;
  onHide: () => void;
}

export const RedditPostActions: React.FC<RedditPostActionsProps> = ({
  post,
  onHide,
}) => (
  <>
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        color: "orange",
        ml: 1,
        p: 1,
      }}
    >
      <ArrowCircleUp sx={{ mr: 0.5 }} />
      <Typography variant="caption">{post.content.counts.score}</Typography>
    </Box>

    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        color: "cornflowerblue",
        ml: 1,
        p: 1,
      }}
    >
      <Comment sx={{ mr: 0.5 }} />
      <Typography variant="caption">{post.content.counts.comments}</Typography>
    </Box>

    <HidePostButton sx={{ ml: 1 }} post={post} onHide={onHide} />

    <IconButton
      sx={{ ml: 1 }}
      component={ExternalLink}
      href={post.content.source}
    >
      <Launch />
    </IconButton>
  </>
);
