import { Box } from "@mui/system";
import * as React from "react";

export interface HoverableLabelProps {
  startOpen?: boolean;
  edgeSize: string | number;
}

export const HoverableLabel: React.FC<HoverableLabelProps> = ({
  startOpen = false,
  edgeSize,
  children,
}) => {
  const [open, setOpen] = React.useState<boolean | "force">(startOpen);
  const [contentBox, setContentBox] = React.useState(null);
  const contentRef = React.useCallback((node) => {
    if (node) {
      const computedStyle = window.getComputedStyle(node);
      setContentBox({
        width: computedStyle.width,
        height: computedStyle.height,
      });
    }
  }, []);

  return (
    <Box
      sx={{
        width: open ? contentBox?.width : edgeSize,
        height: contentBox?.height,
        position: "relative",
        overflow: "hidden",
        transition: "width 200ms ease-in-out",
      }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => {
        if (open !== "force") setOpen(startOpen);
      }}
      onClick={() => setOpen("force")}
    >
      <Box
        ref={contentRef}
        sx={{
          position: "absolute",
          pointerEvents: open ? undefined : "none",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
