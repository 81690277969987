import { Divider, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import * as React from "react";

export interface TextDividerProps {
  sx?: SxProps;
}

export const TextDivider: React.FC<TextDividerProps> = ({ sx, children }) => (
  <Divider
    sx={{
      mt: 1,
      mb: 1,
      ...sx,
    }}
  >
    <Typography variant="button" sx={{ pl: 1, pr: 1 }}>
      {children}
    </Typography>
  </Divider>
);
