import { Apps, FormatListBulleted } from "@mui/icons-material";
import {
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  useTheme,
  Skeleton,
} from "@mui/material";
import * as React from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import { TextDivider } from "components/TextDivider";
import { CollectionList } from "./CollectionList";
import { SideBarContainer } from "./SideBarContainer";
import { TopicList } from "./TopicList";

export interface SideBarProps {
  isOpen: boolean;
  onChange: (isOpen: boolean) => void;
}

export const SideBar: React.FC<SideBarProps> = ({ isOpen, onChange }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const parsedPathname = pathname.split("/");

  const section = parsedPathname[1];
  const sectionId = parsedPathname[2];

  return (
    <SideBarContainer isOpen={isOpen} onChange={onChange}>
      <TextDivider
        sx={{
          "& > .MuiDivider-wrapper": { marginBottom: "-0.75em" },
        }}
      >
        COLLECTIONS
      </TextDivider>
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          onClick={() => onChange(false)}
          selected={!section}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
            <Apps />
          </ListItemIcon>
          <ListItemText>
            <Typography noWrap>All</Typography>
          </ListItemText>
        </ListItem>

        <CollectionList>
          {({ collections, loading }) =>
            loading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  height={32}
                  sx={{ m: 1 }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  height={32}
                  sx={{ m: 1 }}
                  animation="wave"
                />
              </>
            ) : (
              <>
                {[...collections].map((collection) => (
                  <ListItem
                    button
                    component={Link}
                    to={`/collections/${collection._id}`}
                    onClick={() => onChange(false)}
                    key={collection._id}
                    selected={
                      section === "collections" && sectionId === collection._id
                    }
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                      <Avatar
                        sx={{
                          width: 24,
                          height: 24,
                          bgcolor: theme.palette.primary.main,
                        }}
                        src={collection.icon}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography noWrap>{collection.name}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </>
            )
          }
        </CollectionList>
      </List>

      <TextDivider
        sx={{
          "& > .MuiDivider-wrapper": { marginBottom: "-0.75em" },
        }}
      >
        TOPICS
      </TextDivider>
      <List>
        <TopicList>
          {({ topics, loading }) =>
            loading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  height={32}
                  sx={{ m: 1 }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  height={32}
                  sx={{ m: 1 }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  height={32}
                  sx={{ m: 1 }}
                  animation="wave"
                />
              </>
            ) : (
              <>
                {[...topics].map((topic) => (
                  <ListItem
                    button
                    component={Link}
                    to={`/topics/${topic._id}`}
                    onClick={() => onChange(false)}
                    key={topic._id}
                    selected={section === "topics" && sectionId === topic._id}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                      <Avatar
                        sx={{
                          width: 24,
                          height: 24,
                          bgcolor: theme.palette.primary.main,
                        }}
                        src={topic.iconUrl}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography noWrap>{topic.name}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </>
            )
          }
        </TopicList>
      </List>

      <TextDivider
        sx={{
          "& > .MuiDivider-wrapper": { marginBottom: "-0.75em" },
        }}
      >
        MISC
      </TextDivider>
      <List>
        <ListItem
          button
          component={Link}
          to="/integrations"
          onClick={() => onChange(false)}
          selected={section === "integrations"}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
            <FormatListBulleted />
          </ListItemIcon>
          <ListItemText>
            <Typography noWrap>Integrations</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </SideBarContainer>
  );
};
